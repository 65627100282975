/* tslint:disable */
/* eslint-disable */
/**
 * A beautiful and amazing AutomatedLoadTestingPlatform
 * An example service, deployed with the Octane Service creator, which will echo the string
 *
 * The version of the OpenAPI document: 2023-06-10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetConfigResponseContent
 */
export interface GetConfigResponseContent {
    /**
     * 
     * @type {LoadTestConfig}
     * @memberof GetConfigResponseContent
     */
    'config'?: LoadTestConfig;
}
/**
 * 
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof InvalidInputExceptionResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ListConfigsResponseContent
 */
export interface ListConfigsResponseContent {
    /**
     * 
     * @type {Array<LoadTestConfig>}
     * @memberof ListConfigsResponseContent
     */
    'configs'?: Array<LoadTestConfig>;
}
/**
 * 
 * @export
 * @interface ListSchedulesForConfigResponseContent
 */
export interface ListSchedulesForConfigResponseContent {
    /**
     * 
     * @type {Array<ScheduledLoadTest>}
     * @memberof ListSchedulesForConfigResponseContent
     */
    'schedules'?: Array<ScheduledLoadTest>;
}
/**
 * 
 * @export
 * @interface ListSchedulesResponseContent
 */
export interface ListSchedulesResponseContent {
    /**
     * 
     * @type {Array<ScheduledLoadTest>}
     * @memberof ListSchedulesResponseContent
     */
    'schedules'?: Array<ScheduledLoadTest>;
}
/**
 * 
 * @export
 * @interface LoadTestConfig
 */
export interface LoadTestConfig {
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'cell'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'frequency'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoadTestConfig
     */
    'webhooks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoadTestConfig
     */
    'webhooksForTestConfig'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'creator'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoadTestConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'wikiTemplateName'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'useInstanceProfileCreds'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LoadTestConfig
     */
    'cti'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'tpsGenParams'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'transactionCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'asgParams'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'carnaval'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'abortCarnaval'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoadTestConfig
     */
    'interactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'endpoint'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'functionDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'startTps'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'endTps'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'deltaTps'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'deltaSleepTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'sleepAfterRampUp'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof LoadTestConfig
     */
    'numberOfTapAccounts'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'numFunctionsPerAccount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'payloadSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'concurrency'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoadTestConfig
     */
    'loadTestResultWikiPrefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoadTestConfig
     */
    'rampUpPeriod'?: number;
}
/**
 * 
 * @export
 * @interface ScheduledLoadTest
 */
export interface ScheduledLoadTest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'configId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'scheduledDay'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'endTime'?: string;
    /**
     * 
     * @type {TestStatus}
     * @memberof ScheduledLoadTest
     */
    'status'?: TestStatus;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledLoadTest
     */
    'ports'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'wiki'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledLoadTest
     */
    'sim'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledLoadTest
     */
    'actualEndTps'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScheduledLoadTest
     */
    'tpsGenCommands'?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledLoadTest
     */
    'upcomingTestNotificationSent'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledLoadTest
     */
    'accountIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ServiceExceptionResponseContent
 */
export interface ServiceExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ServiceExceptionResponseContent
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TestStatus {
    PendingApproval = 'PENDING_APPROVAL',
    Approved = 'APPROVED',
    Running = 'RUNNING',
    CompletedSuccessful = 'COMPLETED_SUCCESSFUL',
    CompletedAborted = 'COMPLETED_ABORTED'
}

/**
 * 
 * @export
 * @interface UpdateConfigRequestContent
 */
export interface UpdateConfigRequestContent {
    /**
     * 
     * @type {LoadTestConfig}
     * @memberof UpdateConfigRequestContent
     */
    'config': LoadTestConfig;
}
/**
 * 
 * @export
 * @interface UpdateConfigResponseContent
 */
export interface UpdateConfigResponseContent {
    /**
     * 
     * @type {LoadTestConfig}
     * @memberof UpdateConfigResponseContent
     */
    'config'?: LoadTestConfig;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GetConfig operation returns the load test config with the given configId.
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (configId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('getConfig', 'configId', configId)
            const localVarPath = `/configs/{configId}`
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication smithy.api.httpBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ListConfigs returns all load test configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication smithy.api.httpBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ListSchedules operation returns all load tests scheduled in the given date range. Dates must be in the format yyyy-MM-dd.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchedules: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication smithy.api.httpBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ListSchedulesForConfig operation returns all upcoming scheduled load tests with the given configId, and can optionally include all past tests.
         * @param {string} configId 
         * @param {string} [includePastTests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchedulesForConfig: async (configId: string, includePastTests?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('listSchedulesForConfig', 'configId', configId)
            const localVarPath = `/configs/{configId}/schedules`
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication smithy.api.httpBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includePastTests !== undefined) {
                localVarQueryParameter['includePastTests'] = includePastTests;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * UpdateConfig operation updates the load test config with the given configId.
         * @param {string} configId 
         * @param {UpdateConfigRequestContent} updateConfigRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig: async (configId: string, updateConfigRequestContent: UpdateConfigRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('updateConfig', 'configId', configId)
            // verify required parameter 'updateConfigRequestContent' is not null or undefined
            assertParamExists('updateConfig', 'updateConfigRequestContent', updateConfigRequestContent)
            const localVarPath = `/configs/{configId}`
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication smithy.api.httpBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConfigRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * GetConfig operation returns the load test config with the given configId.
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(configId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(configId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ListConfigs returns all load test configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConfigsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ListSchedules operation returns all load tests scheduled in the given date range. Dates must be in the format yyyy-MM-dd.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSchedules(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSchedulesResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSchedules(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ListSchedulesForConfig operation returns all upcoming scheduled load tests with the given configId, and can optionally include all past tests.
         * @param {string} configId 
         * @param {string} [includePastTests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSchedulesForConfig(configId: string, includePastTests?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSchedulesForConfigResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSchedulesForConfig(configId, includePastTests, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * UpdateConfig operation updates the load test config with the given configId.
         * @param {string} configId 
         * @param {UpdateConfigRequestContent} updateConfigRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfig(configId: string, updateConfigRequestContent: UpdateConfigRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateConfigResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfig(configId, updateConfigRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * GetConfig operation returns the load test config with the given configId.
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(configId: string, options?: any): AxiosPromise<GetConfigResponseContent> {
            return localVarFp.getConfig(configId, options).then((request) => request(axios, basePath));
        },
        /**
         * ListConfigs returns all load test configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigs(options?: any): AxiosPromise<ListConfigsResponseContent> {
            return localVarFp.listConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * ListSchedules operation returns all load tests scheduled in the given date range. Dates must be in the format yyyy-MM-dd.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchedules(startDate?: string, endDate?: string, options?: any): AxiosPromise<ListSchedulesResponseContent> {
            return localVarFp.listSchedules(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * ListSchedulesForConfig operation returns all upcoming scheduled load tests with the given configId, and can optionally include all past tests.
         * @param {string} configId 
         * @param {string} [includePastTests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchedulesForConfig(configId: string, includePastTests?: string, options?: any): AxiosPromise<ListSchedulesForConfigResponseContent> {
            return localVarFp.listSchedulesForConfig(configId, includePastTests, options).then((request) => request(axios, basePath));
        },
        /**
         * UpdateConfig operation updates the load test config with the given configId.
         * @param {string} configId 
         * @param {UpdateConfigRequestContent} updateConfigRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(configId: string, updateConfigRequestContent: UpdateConfigRequestContent, options?: any): AxiosPromise<UpdateConfigResponseContent> {
            return localVarFp.updateConfig(configId, updateConfigRequestContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * GetConfig operation returns the load test config with the given configId.
     * @param {string} configId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConfig(configId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConfig(configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ListConfigs returns all load test configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConfigs(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ListSchedules operation returns all load tests scheduled in the given date range. Dates must be in the format yyyy-MM-dd.
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSchedules(startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSchedules(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ListSchedulesForConfig operation returns all upcoming scheduled load tests with the given configId, and can optionally include all past tests.
     * @param {string} configId 
     * @param {string} [includePastTests] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSchedulesForConfig(configId: string, includePastTests?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSchedulesForConfig(configId, includePastTests, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * UpdateConfig operation updates the load test config with the given configId.
     * @param {string} configId 
     * @param {UpdateConfigRequestContent} updateConfigRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateConfig(configId: string, updateConfigRequestContent: UpdateConfigRequestContent, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateConfig(configId, updateConfigRequestContent, options).then((request) => request(this.axios, this.basePath));
    }
}


