import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {useState} from "react";
import {headerSelector, Navigation} from "../common-components";
import LoadTestsTable from "../schedules-table/Table";

export default function SchedulesView() {
    const [navOpen, setNavOpen] = useState(true);
    return (
        <AppLayout
            navigation={<Navigation/>}
            content={<LoadTestsTable/>}
            contentType="table"
            navigationOpen={navOpen}
            onNavigationChange={event => setNavOpen(event.detail.open)}
            headerSelector={headerSelector}
            toolsHide={true}
        />
    );
}
