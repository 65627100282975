export interface IAppSettings {
  apiUrl: string
}

let cache = {};
let user = "";

export function setUser(authUser: string) {
  user = authUser;
}

export function getUser(){
  return user;
}

export function getAppSetting(key: string) {
  return cache[key];
}


export function initializeAppSettings(settings: IAppSettings) {
  cache = { ...settings };
  Object.freeze(cache);
}
