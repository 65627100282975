import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import {useState} from "react";
import {headerSelector, Navigation} from "../common-components";
import ConfigsTable from "../loadtestconfigs-table/Table";

export default function ConfigsView() {
    const [navOpen, setNavOpen] = useState(true);
    return (
        <AppLayout
            navigation={<Navigation/>}
            content={<ConfigsTable/>}
            contentType="table"
            navigationOpen={navOpen}
            onNavigationChange={event => setNavOpen(event.detail.open)}
            headerSelector={headerSelector}
            toolsHide={true}
        />
    );
}
