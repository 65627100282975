import {AppLayout, SideNavigationProps} from "@amzn/awsui-components-react";
import {Breadcrumbs, headerSelector, Navigation, navItems} from "../common-components";
import ConfigDetail from "./ConfigDetail";
import {useParams} from "react-router";

export default function ConfigDetailView() {
    const {configId} = useParams<{ configId: string }>();
    const newNavItems: SideNavigationProps.Item[] = [navItems[0],
        {
            type: 'link-group', text: 'Configs', href: '#/configs', items: [{
                type: 'link',
                text: 'Config Details',
                href: `#/configs/${configId}`
            }]
        }];
    return (
        <AppLayout
            navigation={<Navigation items={newNavItems}/>}
            content={<ConfigDetail configId={configId}/>}
            headerSelector={headerSelector}
            toolsHide={true}
            breadcrumbs={<Breadcrumbs items={[{text: 'Configs', href: '#/configs'}, {text: configId!, href: ''}]}/>}
        />
    )
}
