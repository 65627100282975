import React from "react";
import {Route, Routes} from 'react-router-dom';
import CommonHeader from "./CommonHeader";
import ConfigDetailView from "./loadtestconfig";
import ConfigsView from "./loadtestconfigs";
import SchedulesView from "./schedules";
import './styles.scss';

export default function App() {

    return (
        <div>
            <CommonHeader/>
            <Routes>
                <Route path="/" element={<SchedulesView/>}/>
                <Route path="/configs" element={<ConfigsView/>}/>
                <Route path="/configs/:configId" element={<ConfigDetailView/>}/>
            </Routes>
        </div>
    );
}
