// Promise polyfill
import 'core-js/features/promise';

import { createRoot } from "react-dom/client";
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import { initializeAppSettings, setUser } from './config/AppSettings';
import jwt_decode, { JwtPayload } from 'jwt-decode';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});


(async () => {
  // Make sure Midway is present before rendering
  const jwtToken =  await getMidwayJwtToken();
  const decodedJwtToken: JwtPayload = jwt_decode(jwtToken);
  const user = decodedJwtToken.sub!;
  setUser(user);



  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  const container = document.getElementById("app");
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript

  root.render(
    <HashRouter>
      <App />
    </HashRouter>
  );
})();
