import {TopNavigation} from "@amzn/awsui-components-react";

export default function CommonHeader() {
    return (
        <div id="header">
            <TopNavigation
                identity={{
                    href: "#",
                    title: "Automated Load Testing Platform",
                    logo: {
                        src: "https://drive-render.corp.amazon.com/view/sdekunal%40/AWS_Logo.jpg",
                        alt: "Automated Load Testing Platform"
                    }
                }}
                i18nStrings={{
                    searchIconAriaLabel: "Search",
                    searchDismissIconAriaLabel: "Close search",
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu"
                }}
            />
        </div>
    );
}
