import {Link, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import {TableProps} from '@amzn/awsui-components-react/polaris/table';

export interface ScheduledLoadTestTableRow {
    configId?: string;
    name?: string;  // from Config
    scheduledDay?: string;
    status?: string;
    actualEndTps?: number;
    wiki?: string;
}

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ScheduledLoadTestTableRow>[] = [
    {
        id: 'configId',
        header: 'Config ID',
        cell: item => (<Link href={`#/configs/${item.configId!}`}>{item.configId}</Link>),
        sortingField: 'configId',
        width: 150,
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        sortingField: 'name',
    },
    {
        id: 'scheduledDay',
        header: 'Scheduled Day',
        cell: item => item.scheduledDay,
        sortingField: 'scheduledDay',
        width: 190,
    },
    {
        id: 'status',
        header: 'Status',
        cell: item => getStatusIndicator(item.status),
        sortingField: 'status',
        width: 230,
    },
    {
        id: 'actualEndTps',
        header: 'End TPS',
        cell: item => (item.actualEndTps ? item.actualEndTps : '-'),
        width: 110,
    },
    {
        id: 'resultsLink',
        header: 'Results',
        cell: item => (!validURL(item.wiki) ? item.wiki : <Link href={item.wiki} target='_blank'>{item.wiki}</Link>)
    }
];

function getStatusIndicator(status: string | undefined) {
    if (!status) {
        return '-';
    }

    let indicatorType: string = '';
    switch (status) {
        case 'PENDING_APPROVAL':
            indicatorType = 'pending';
            break;
        case 'APPROVED':
        case 'COMPLETED_SUCCESSFUL':
            indicatorType = 'success';
            break;
        case 'RUNNING':
            indicatorType = 'in-progress';
            break;
        case 'COMPLETED_ABORTED':
            indicatorType = 'stopped';
            break;
        case 'DELETED':
        case 'CANCELLED':
        case 'COMPLETED_ABORTED_ERROR':
            indicatorType = 'error';
            break;
    }

    if (indicatorType) {
        return <StatusIndicator type={indicatorType as StatusIndicatorProps.Type}>{status}</StatusIndicator>
    }
    return status;
}

function validURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}
