import {Link, StatusIndicator} from "@amzn/awsui-components-react";
import {TableProps} from '@amzn/awsui-components-react/polaris/table';
import {LoadTestConfig} from '../../loadtest-api/generated-src';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<LoadTestConfig>[] = [
    {
        id: 'configId',
        header: 'Config ID',
        cell: item => (<Link href={`#/configs/${item.id!}`}>{item.id}</Link>),
        sortingField: 'id',
    },
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        sortingField: 'name',
    },
    {
        id: 'enabled',
        header: 'Enabled?',
        cell: item => (
            <StatusIndicator
                type={item.enabled ? 'success' : 'error'}>{item.enabled ? 'Enabled' : 'Disabled'}</StatusIndicator>
        ),
        sortingField: 'enabled',
    },
    {
        id: 'stage',
        header: 'Stage',
        cell: item => item.stage,
        sortingField: 'stage',
    },
    {
        id: 'region',
        header: 'Region',
        cell: item => item.region,
        sortingField: 'region',
    },
    {
        id: 'carnaval',
        header: 'Carnaval',
        cell: item => item.carnaval,
        sortingField: 'carnaval',
    },
    {
        id: 'startTps',
        header: 'Start TPS',
        cell: item => item.startTps != null ? item.startTps : '-',
        sortingField: 'startTps',
    },
    {
        id: 'endTps',
        header: 'End TPS',
        cell: item => item.endTps != null ? item.endTps : '-',
        sortingField: 'endTps',
    },
    {
        id: 'deltaTps',
        header: 'Delta TPS',
        cell: item => item.deltaTps != null ? item.deltaTps : '-',
        sortingField: 'deltaTps',
    }
];
