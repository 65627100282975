import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {AxiosResponse} from "axios";
import {useEffect, useState} from 'react';
import {DefaultApi, GetConfigResponseContent} from "../../loadtest-api/generated-src";
import LoadTestApiFactory from '../../loadtest-api/LoadTestApiFactory';
import {EmptyState, getMatchesCountText, paginationLabels} from "../common-components";
import {COLUMN_DEFINITIONS, ScheduledLoadTestTableRow} from './table-config';

export default function LoadTestsTable() {
    const [allItems, setAllItems] = useState<ScheduledLoadTestTableRow[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);

    const [tableTitle, setTableTitle] = useState('Recent/Upcoming Scheduled Load Tests');

    const LoadTestApi = LoadTestApiFactory();

    // Default date range for query
    const dateRadius = 7;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - dateRadius);
    const [startDateStr, setStartDateStr] = useState(startDate.toISOString());
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + dateRadius);
    const [endDateStr, setEndDateStr] = useState(endDate.toISOString());

    useEffect(() => {
        (async () => {
            const schedules = (await LoadTestApi.listSchedules(startDateStr, endDateStr)).data.schedules;
            if (schedules) {
                setAllItems(await Promise.all(schedules!.map(async (s): Promise<ScheduledLoadTestTableRow> => {
                    const row: ScheduledLoadTestTableRow = s;
                    const config = (await getConfigPromise(LoadTestApi, s.configId!)).data.config
                    row.name = (config && config.name ? config.name : '-');
                    return row;
                })));
            } else {
                setAllItems([]);
            }
            console.log("updated load tests table");
        })();
    }, [refreshTableFlag]);

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<Button iconName='refresh'
                                        onClick={() => setRefreshTableFlag(!refreshTableFlag)}>Refresh</Button>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
        <Table
            {...collectionProps}
            loading={!allItems}
            loadingText="Loading instances"
            selectionType="single"
            resizableColumns
            header={
                <Header
                    counter={
                        allItems && `(${allItems.length})`
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            <Button
                                onClick={() => {
                                    setStartDateStr('');
                                    setEndDateStr('');
                                    setRefreshTableFlag(!refreshTableFlag);
                                    setTableTitle('All Scheduled Load Tests')
                                }}
                            >
                                View All Load Tests
                            </Button>
                            <Button
                                disabled={selectedItems!.length == 0}
                                href={selectedItems!.length ? `#/configs/${selectedItems![0].configId!}` : ``}
                            >
                                View Config
                            </Button>
                        </SpaceBetween>
                    }
                >
                    {tableTitle}
                </Header>
            }
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
    );
}

const configPromiseCache = new Map<string, Promise<AxiosResponse<GetConfigResponseContent>>>();

// Gets a Promise to query backend API for a particular configId
function getConfigPromise(LoadTestApi: DefaultApi, configId: string): Promise<AxiosResponse<GetConfigResponseContent>> {
    if (!configPromiseCache.has(configId)) {
        console.log(`config ${configId} Promise cache miss, querying API...`)

        const configPromise = LoadTestApi.getConfig(configId)
            .catch((error) => {
                configPromiseCache.delete(configId);
                return Promise.reject(error);
            });
        configPromiseCache.set(configId, configPromise);
    }

    return configPromiseCache.get(configId)!;
}
