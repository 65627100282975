import {useEffect, useState} from 'react'
import {
    Box,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    Link,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react";
import LoadTestApiFactory from "../../loadtest-api/LoadTestApiFactory";
import {LoadTestConfig} from "../../loadtest-api/generated-src";

export default function ConfigDetail({configId}) {
    const LoadTestApi = LoadTestApiFactory();

    const [data, setData] = useState<LoadTestConfig>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setData((await LoadTestApi.getConfig(configId!)).data.config);
            setLoading(false);
            console.log("updated config detail");
        })();
    }, []);

    const loadingStatus = (<StatusIndicator type="loading">Loading...</StatusIndicator>);
    const invalidConfigStatus = (<StatusIndicator type="error">Invalid Config ID</StatusIndicator>);

    const details = <ConfigDetails config={data}/>;

    return (
        <ContentLayout
            header={<Header variant="h1">{configId}</Header>}
        >
            <SpaceBetween size="l">
                <Container
                    header={
                        <Header variant="h2">
                            Load Test Configuration
                        </Header>}
                >
                    {loading ? loadingStatus : (data ? details : invalidConfigStatus)}
                </Container>
            </SpaceBetween>
        </ContentLayout>
    )
}

const ConfigDetails = ({config}) => (
    <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="m">
            <div>
                <Box variant="awsui-key-label">Config ID</Box>
                <div>{config.id}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Version</Box>
                <div>{config.version != null ? config.version : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Name</Box>
                <div>{config.name != null ? config.name : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Creator</Box>
                <div>{config.creator != null ? config.creator : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">CTI</Box>
                <div>{config.cti != null ? ctiDisplay(config.cti) : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Distribution</Box>
                <div>{config.distribution != null ? config.distribution : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Stage</Box>
                <div>{config.stage != null ? config.stage : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Region</Box>
                <div>{config.region != null ? config.region : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Cell</Box>
                <div>{config.cell != null ? config.cell : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Frequency</Box>
                <div>{config.frequency != null ? config.frequency : '-'}</div>
            </div>
        </SpaceBetween>

        <SpaceBetween size="m">
            <div>
                <Box variant="awsui-key-label">Endpoint</Box>
                <div>{config.endpoint != null ? config.endpoint : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Ramp Up Period</Box>
                <div>{config.rampUpPeriod != null ? config.rampUpPeriod : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Sleep after Ramp Up</Box>
                <div>{config.sleepAfterRampUp != null ? config.sleepAfterRampUp : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Delta Sleep Time</Box>
                <div>{config.deltaSleepTime != null ? config.deltaSleepTime : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">TPS Gen Commands</Box>
                <div>{config.tpsGenCommands != null ? config.tpsGenCommands : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">TPS Gen Params</Box>
                <div>{config.tpsGenParams != null ? config.tpsGenParams : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Transaction Creator</Box>
                <div>{config.transactionCreator != null ? config.transactionCreator : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Function Duration</Box>
                <div>{config.functionDuration != null ? config.functionDuration : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Payload Size</Box>
                <div>{config.payloadSize != null ? config.payloadSize : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Concurrency</Box>
                <div>{config.concurrency != null ? config.concurrency : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Number of Functions Per Account</Box>
                <div>{config.numFunctionsPerAccount != null ? config.numFunctionsPerAccount : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Number of TAP Accounts</Box>
                <div>{config.numberOfTapAccounts != null ? mapDisplay(config.numberOfTapAccounts) : '-'}</div>
            </div>
        </SpaceBetween>

        <SpaceBetween size="m">
            <div>
                <Box variant="awsui-key-label">Enabled?</Box>
                <div>{config.enabled != null ? enabledDisplay(config.enabled) : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Carnaval</Box>
                <div>{config.carnaval != null ? config.carnaval : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">ASG Params</Box>
                <div>{config.asgParams != null ? config.asgParams : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Abort Carnaval</Box>
                <div>{config.abortCarnaval != null ? config.abortCarnaval : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Start TPS</Box>
                <div>{config.startTps != null ? config.startTps : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">End TPS</Box>
                <div>{config.endTps != null ? config.endTps : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Delta TPS</Box>
                <div>{config.deltaTps != null ? config.deltaTps : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Results Wiki Prefix</Box>
                <div>{config.loadTestResultWikiPrefix != null ? config.loadTestResultWikiPrefix : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Wiki Template Name</Box>
                <div>{config.wikiTemplateName != null ? config.wikiTemplateName : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Webhooks</Box>
                <div>{config.webhooks != null ? listDisplay(config.webhooks) : '-'}</div>
            </div>
            <div>
                <Box variant="awsui-key-label">Webhooks for Test Config</Box>
                <div>{config.webhooksForTestConfig != null ? listDisplay(config.webhooksForTestConfig) : '-'}</div>
            </div>
        </SpaceBetween>
    </ColumnLayout>
);

function ctiDisplay(cti) {
    return (<SpaceBetween size="xxs">
        <div>
            <Box variant="awsui-key-label">Category</Box>
            <div>{cti["Category"] != null ? cti["Category"] : '-'}</div>
        </div>
        <div>
            <Box variant="awsui-key-label">Type</Box>
            <div>{cti["Type"] != null ? cti["Type"] : '-'}</div>
        </div>
        <div>
            <Box variant="awsui-key-label">Item</Box>
            <div>{cti["Item"] != null ? cti["Item"] : '-'}</div>
        </div>
    </SpaceBetween>);
}

function listDisplay(l) {
    return (
        <SpaceBetween size="xxs">
            {l.map((d) =>
                <div key={d}><Link href={d}>{d}</Link></div>
            )}
        </SpaceBetween>
    );
}

function mapDisplay(data) {
    return (
        <SpaceBetween size="xxs">
            {Object.keys(data).map((k) => <div key={k}>
                <Box variant="awsui-key-label">{k}</Box>
                {data[k]}
            </div>)}
        </SpaceBetween>
    );
}

function enabledDisplay(enabled) {
    if (enabled) {
        return (<StatusIndicator type="success">Enabled</StatusIndicator>);
    }
    return (<StatusIndicator type="error">Disabled</StatusIndicator>);
}
