import {BreadcrumbGroup, BreadcrumbGroupProps, SideNavigation, SideNavigationProps} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import {RadioGroupProps} from "@amzn/awsui-components-react/polaris/radio-group";
import {ReactNode} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export const navHeader = {text: 'Automated Load Testing', href: '#/'};
export const navItems: SideNavigationProps.Item[] = [
    {type: 'link', text: 'Schedules', href: '#/'},
    {type: 'link', text: 'Configs', href: '#/configs'}
];

export const headerSelector = "#header";

export function Navigation({items = navItems}) {
    const location = useLocation();
    const navigate = useNavigate();

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            navigate(event.detail.href.substring(1));
        }
    };
    return <SideNavigation items={items} header={navHeader} activeHref={`#${location.pathname}`}
                           onFollow={onFollowHandler}/>;
}

export function Breadcrumbs({items}: { items: BreadcrumbGroupProps.Item[] }) {
    return <BreadcrumbGroup items={[navHeader, ...items]}/>;
}


export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}

export function EmptyState({title, subtitle, action}: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{bottom: 's'}} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
    {value: 'table', label: 'Table'},
    {value: 'cards', label: 'Cards'}
];
